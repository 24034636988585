export const MOODBOARD_TYPES: Record<string, string> = {
  moodboard: 'moodboard',
  plan: 'perspective',
  collage: 'collage',
};

// Define routes with specific types
export const MOODBOARD_ROUTES: Record<string, string> = {
  moodboard: '/dashboard/moodboard',
  plan: '/dashboard/plan',
  collage: '/dashboard/collage',
};
