import { MOODBOARD_TYPES, MOODBOARD_ROUTES } from './constants';

export const isMoodboardRoute = (pathname: string) => {
  return (
    pathname.includes('/dashboard/creations') ||
    pathname.includes('/dashboard/presentations')
  );
};

export const getMoodboardType = (pathname: string) => {
  let type;
  for (const key in MOODBOARD_ROUTES) {
    if (pathname.includes(MOODBOARD_ROUTES[key])) {
      type = MOODBOARD_TYPES[key];
      break;
    }
  }
  return type || MOODBOARD_TYPES.moodboard;
};

function dataURLtoBlob(dataurl: any) {
  if (!dataurl) return null;
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export const blobToFile = (blob: any, fileName: string) => {
  return new File([blob], fileName, {
    type: 'image/png',
  });
};

export function b64toBlob(b64Data: string, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function dataURLtoFile(dataurl: any, filename: string) {
  const blob = dataURLtoBlob(dataurl);
  const file = blobToFile(blob, filename);
  return file;
}

export const fileToArrayBuffer = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target?.result);
    };
    reader.onerror = (e) => {
      reject(e.target?.error);
    };
    reader.readAsArrayBuffer(file);
  });
};

export function base64ToFile(base64: string, filename: string) {
  const arr = base64.split(',');
  const mimeMatch = arr[0].match(/:(.*?);/);

  if (!mimeMatch) {
    throw new Error('Invalid base64 format');
  }

  const mime = mimeMatch[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function downloadImageFromDataURL(dataURL: string, fileName: string) {
  const a = document.createElement('a');
  a.href = dataURL;
  a.download = fileName || 'download';
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
