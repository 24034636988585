import { Grid, Fade, Typography } from '@mui/material';
import Footer from 'components/Footer/Footer';
import Navbar from 'components/Navbar/Navbar';
import ScrollToHashElement from 'components/Templates/Misc/ScrollToHashElement';
import Title from 'components/Templates/Titles/Title';

const Legal = () => {
  return (
    <Grid container>
      <Navbar />
      <ScrollToHashElement />
      <Fade in={true} timeout={500}>
        <Grid container>
          <Grid
            item
            flexDirection="column"
            sx={{
              p: 5,
              display: 'flex',
              gap: '1rem',
            }}
          >
            <Title title="Mentions légales" />
            <Typography
              component="span"
              sx={{
                fontFamily: 'Bodoni Moda',
                fontSize: '1.5rem',
                fontWeight: 700,
              }}
            >
              Informations Légales
            </Typography>
            <Typography>
              Raison sociale : SARL MOLYXA <br />
              <br />
              Adresse : 6 avenue du Grand Large 34300 AGDE <br />
              <br />
              Téléphone : 04 67 31 82 43 <br />
              <br />
              Email : contact@molyxa.com <br />
              <br />
              Forme juridique : Société à responsabilité limitée <br />
              <br />
              Secteur d’activité : Conception assistée par ordinateur et
              services d'intelligence artificielle pour l'architecture
              d'intérieur <br />
              <br />
              Capital social : 10 000,000 EUR <br />
              <br />
              SIREN : 950718312 <br />
              <br />
              Numéro de TVA intracommunautaire : FR25950718312 <br />
              <br />
              <strong>Gérant</strong> : Mme. Alexandra BOUSSAGOL <br />
              <br />
              <strong>Directeur de publication</strong> : Mme. Alexandra
              BOUSSAGOL <br />
              <br />
              <strong>Stratégie éditoriale et rédaction des contenus</strong> :
              Mme. Alexandra BOUSSAGOL <br />
              <br />
              <strong>Hébergement</strong> : IONOS <br />
              <br />
              <strong>Développement du site internet</strong> : SARL QUERIO{' '}
              <br />
              <br />
              <strong>Crédits Photos et Illustrations</strong> : Fournies par
              les utilisateurs ou achetées sur des banques d'images sous
              licence. <br />
              <br />
            </Typography>
            <Typography
              component="span"
              sx={{
                fontFamily: 'Bodoni Moda',
                fontSize: '1.5rem',
                fontWeight: 700,
              }}
            >
              Propriété intellectuelle
            </Typography>
            <Typography>
              Le site internet, sa structure générale, ainsi que tous les
              contenus, dessins, graphiques et images sont la propriété de la
              société SARL MOLYXA. Ces éléments sont protégés par les lois sur
              la propriété intellectuelle. Toute reproduction ou diffusion,
              totale ou partielle, sans l'accord préalable et écrit de SARL
              MOLYXA, est strictement interdite.
            </Typography>
            <Typography
              component="span"
              sx={{
                fontFamily: 'Bodoni Moda',
                fontSize: '1.5rem',
                fontWeight: 700,
              }}
            >
              Liens externes
            </Typography>
            <Typography>
              Le site peut contenir des liens vers d’autres sites web ou sources
              internet. SARL MOLYXA ne contrôle pas ces sites et ne peut être
              tenu responsable de leur contenu ni des collectes de données
              personnelles.
            </Typography>
            <Typography
              component="span"
              sx={{
                fontFamily: 'Bodoni Moda',
                fontSize: '1.5rem',
                fontWeight: 700,
              }}
            >
              Protection des données personnelles
            </Typography>
            <Typography>
              Les données recueillies sur le site servent à la communication
              avec les clients et ne sont pas partagées avec des tiers.
              Conformément à la loi n° 78-17 du 6 janvier 1978, modifiée, vous
              disposez de droits d'accès, de modification, et de suppression des
              données vous concernant, exercices possibles à l'adresse suivante
              : [adresse e-mail].
            </Typography>
            <Typography
              component="span"
              sx={{
                fontFamily: 'Bodoni Moda',
                fontSize: '1.5rem',
                fontWeight: 700,
              }}
            >
              Cookies
            </Typography>
            <Typography>
              Des cookies sont utilisés pour améliorer l'expérience utilisateur,
              enregistrant des informations de navigation qui peuvent analyser
              et mesurer l'audience du site. Vous pouvez gérer vos préférences
              de cookies directement dans les paramètres de votre navigateur.
            </Typography>
          </Grid>
          <Footer />
        </Grid>
      </Fade>
    </Grid>
  );
};

export default Legal;

