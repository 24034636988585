import { useDispatch, useSelector } from 'react-redux';
import UserApi from 'api/user.api';
import { updateUser } from 'redux/slices/user/userSlice';
import { RootState } from 'redux/store/store';

export const useUser = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.userReducer);

  const refetchUser = async () => {
    const user = await UserApi.getUser();
    dispatch(updateUser(user));
  };

  const isClient = user?.role === 'client';
  const isArchitect = user?.role === 'architect';

  return {
    refetchUser,
    user,
    isClient,
    isArchitect,
  };
};

export default useUser;

