import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToHashElement = () => {
  let location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let hash = location.hash;
      const removeHashCharacter = (str: string) => {
        const result = str.slice(1);
        return result;
      };

      if (hash) {
        let element = document.getElementById(removeHashCharacter(hash));

        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            // block: "end",
            inline: 'nearest',
          });
        }
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return null;
};

export default ScrollToHashElement;

