import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme();

export const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    // fontWeightSemiBold: 600,
    fontWeightBold: 700,
    // fontWeightExtraBold: 900,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1100,
      lg: 1500,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: '#8F4526',
      dark: '#7d381b',
      light: '#BB6D3A',
    },
    secondary: {
      main: '#8F4526',
      dark: '#7d381b',
      light: '#BB6D3A',
    },
    text: {
      primary: '#000',
      secondary: '#9F9F9F',
    },
    background: {
      default: '#f1f1f180',
      paper: '#FFFFFF',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: `2.5rem`, //40px
          fontWeight: 'inherit',
          lineHeight: 'inherit',
          margin: 0,
        },
        h2: {
          fontSize: `2rem`, //32px
          fontWeight: 'inherit',
          lineHeight: 'inherit',
          margin: 0,
        },
        h3: {
          fontSize: `1.5625rem`, //25px
          fontWeight: 'inherit',
          lineHeight: 'inherit',
          margin: 0,
        },
        h4: {
          fontSize: `1.375rem`, //22px
          fontWeight: 'inherit',
          lineHeight: 'inherit',
          margin: 0,
        },
        h5: {
          fontSize: `1.125rem`, //18px
          fontWeight: 'inherit',
          lineHeight: 'inherit',
          margin: 0,
        },
        h6: {
          fontSize: `1rem`, //16px
          fontWeight: 'inherit',
          lineHeight: 'inherit',
          margin: 0,
        },
        body1: {
          fontSize: `.875rem`, //14px
          fontWeight: 'inherit',
          lineHeight: 'inherit',
          margin: 0,
        },
        body2: {
          fontSize: `.75rem`, //12px
          fontWeight: 'inherit',
          lineHeight: 'inherit',
          margin: 0,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: '0px 4px 20px -2px rgba(0, 0, 0, 0.10)',
        },
        colorPrimary: {
          color: '#2C630B',
          backgroundColor: 'white',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          transition: 'all .2s ease-in-out !important',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        gutters: {
          [defaultTheme.breakpoints.down('sm')]: {
            paddingLeft: '.8rem',
            paddingRight: '.8rem',
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fill: 'white',
        },
      },
    },
  },
});

