import React, { useEffect } from 'react';
import { theme } from './theme/_MolyxaTheme';
import { ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout, setLoading } from './redux/slices/user/userSlice';
import Fire from './services/fire.service';
import LoadingScreen from './components/Templates/Loading/LoadingScreen';
import UserApi from './api/user.api';
import { getToken, setToken } from './services/localstorage.service';
import AlertContextProvider from './contexts/AlertContext';

import '@fontsource/montserrat/100.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/700.css';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import { useAlert } from './hooks/useAlert';
import * as Sentry from '@sentry/react';
import Routes from 'router/Routes';

Fire.init();

Sentry.init({
  environment: process.env.NODE_ENV || 'development',
  dsn:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_SENTRY_DSN_DEV
      : process.env.REACT_APP_SENTRY_DSN_PROD,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state: any) => state.userReducer);
  const { showAlert } = useAlert();

  const checkUser = async (currentUser: any) => {
    dispatch(setLoading(true));
    if (currentUser) {
      const token = await currentUser.getIdToken();
      setToken(token);

      try {
        const userFromApi = await UserApi.getUser();

        if (!userFromApi || userFromApi.detail === 'User not found') {
          await dispatch(logout());
        } else {
          dispatch(login({ ...userFromApi }));
        }
      } catch (error) {
        showAlert('error', 'Il y a eu une erreur lors de la connexion');
        console.error(error);
        dispatch(setLoading(false));
      }
    } else {
      const token = getToken();
      if (token) {
        try {
          const userFromApi = await UserApi.getUser();

          if (!userFromApi || userFromApi?.detail === 'User not found') {
            await dispatch(logout());
          } else {
            dispatch(login({ ...userFromApi }));
          }
        } catch (error) {
          showAlert('error', 'Il y a eu une erreur lors de la connexion');
          dispatch(setLoading(false));
        }
      } else {
        const token = getToken();
        if (token) {
          try {
            const userFromApi = await UserApi.getUser();

            if (!userFromApi || userFromApi?.detail === 'User not found') {
              await dispatch(logout());
            } else {
              dispatch(login({ ...userFromApi }));
            }
          } catch (error) {
            showAlert('error', 'Il y a eu une erreur lors de la connexion');
            dispatch(setLoading(false));
          }
        }
      }
    }
    dispatch(setLoading(false));
  };

  React.useEffect(() => {
    Fire.auth().onAuthStateChanged(async (user) => {
      checkUser(user);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AlertContextProvider>
      <ThemeProvider theme={theme}>
        {isLoading ? <LoadingScreen /> : <Routes />}
      </ThemeProvider>
    </AlertContextProvider>
  );
}

export default App;

