import React from 'react';
import { useRoutes } from 'react-router-dom';
import Loadable from '../components/Templates/Layouts/Loadable';
import DashboardLayout from '../components/Dashboard/DashboardLayout/DashboardLayout';
import AuthGuard from '../guards/AuthGuard';
import GuestGard from '../guards/GuestGard';
import FreeGuard from '../guards/FreeGuard';
import { wrapUseRoutes } from '@sentry/react';
import Legal from 'components/Legal/Legal';
import CGV from 'components/Legal/CGV';
import { PresentationProvider } from 'components/Dashboard/Moodboard/PresentationContext';
import RedirectToPresentationPage from 'components/Dashboard/Moodboard/RedirectToPresentationPage';
const useSentryRoutes = wrapUseRoutes(useRoutes);

const Home = Loadable(React.lazy(() => import('../components/Home/Home')));
const DesignTools = Loadable(
  React.lazy(() => import('../components/DesignTools/DesignTools'))
);
const Pricing = Loadable(
  React.lazy(() => import('../components/Pricing/Pricing'))
);
const Register = Loadable(
  React.lazy(() => import('../components/Register/Register'))
);
const Login = Loadable(React.lazy(() => import('../components/Login/Login')));
const Page404 = Loadable(
  React.lazy(() => import('../components/Page404/Page404'))
);
const Dashboard = Loadable(
  React.lazy(() => import('../components/Dashboard/Dashboard'))
);
const MoodboardLayout = Loadable(
  React.lazy(() => import('../components/Dashboard/Moodboard/MoodboardLayout'))
);
const Projects = Loadable(
  React.lazy(() => import('../components/Dashboard/Projects/Projects'))
);
const Project = Loadable(
  React.lazy(() => import('../components/Dashboard/Project/Project'))
);
const ProjectLayout = Loadable(
  React.lazy(
    () => import('../components/Dashboard/Project/ProjectLayout/ProjectLayout')
  )
);
const Products = Loadable(
  React.lazy(() => import('../components/Dashboard/Project/Products/Products'))
);
const Product = Loadable(
  React.lazy(() => import('../components/Dashboard/Project/Product/Product'))
);
const Contacts = Loadable(
  React.lazy(() => import('../components/Dashboard/Contacts/Contacts'))
);
const Inspirations = Loadable(
  React.lazy(() => import('../components/Dashboard/Inspirations/Inspirations'))
);
const Catalogue = Loadable(
  React.lazy(() => import('../components/Dashboard/Catalogue/Catalogue'))
);
const CatalogueLayout = Loadable(
  React.lazy(
    () =>
      import(
        '../components/Dashboard/Catalogue/CatalogueLayout/CatalogueLayout'
      )
  )
);
const CatalogueGeneral = Loadable(
  React.lazy(
    () => import('../components/Dashboard/CatalogueGeneral/CatalogueGeneral')
  )
);
const CatalogueGeneralLayout = Loadable(
  React.lazy(
    () =>
      import(
        '../components/Dashboard/CatalogueGeneral/CatalogueGeneralLayout/CatalogueGeneralLayout'
      )
  )
);

const VirtualStaging = Loadable(
  React.lazy(
    () => import('../components/Dashboard/VirtualStaging/VirtualStaging')
  )
);

export default function Routes() {
  return useSentryRoutes([
    {
      path: '/',
      element: (
        <GuestGard>
          <Home />
        </GuestGard>
      ),
    },
    {
      path: '/design-tools',
      element: <DesignTools />,
    },
    {
      path: '/pricing',
      element: <Pricing />,
    },
    {
      path: '/register',
      element: (
        <FreeGuard>
          <Register />
        </FreeGuard>
      ),
    },
    {
      path: '/login',
      element: (
        <GuestGard>
          <Login />
        </GuestGard>
      ),
    },
    {
      path: '/legal',
      element: (
        <GuestGard>
          <Legal />
        </GuestGard>
      ),
    },
    {
      path: '/cgv',
      element: (
        <GuestGard>
          <CGV />
        </GuestGard>
      ),
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <Dashboard />,
        },
        {
          path: 'projects',
          element: <Projects />,
        },
        {
          path: 'projects/:projectId',
          element: <ProjectLayout />,
          children: [
            {
              path: '',
              element: <Project />,
            },
            {
              path: 'products',
              element: <Products />,
            },
          ],
        },
        {
          path: 'contacts',
          element: (
            <AuthGuard roles={['architect']}>
              <Contacts />
            </AuthGuard>
          ),
        },
        {
          path: 'inspirations',
          element: (
            <AuthGuard>
              <Inspirations />
            </AuthGuard>
          ),
        },
        {
          path: 'catalogue',
          element: (
            <AuthGuard roles={['architect']}>
              <CatalogueLayout />
            </AuthGuard>
          ),
          children: [
            {
              path: '',
              element: <Catalogue />,
            },

            {
              path: ':productId',
              element: <Product />,
            },
          ],
        },
        {
          path: 'general-catalogue',
          element: (
            <AuthGuard roles={['architect']}>
              <CatalogueGeneralLayout />
            </AuthGuard>
          ),
          children: [
            {
              path: '',
              element: <CatalogueGeneral />,
            },
            {
              path: ':productId',
              element: <Product />,
            },
          ],
        },
        {
          path: 'creations',
          element: <MoodboardLayout />,
        },
        {
          path: 'presentations',
          element: (
            <PresentationProvider>
              <MoodboardLayout />
            </PresentationProvider>
          ),
        },
        {
          path: 'creations/:moodboardId',
          element: <MoodboardLayout />,
          children: [],
        },
        {
          path: 'presentations/:presentationId',
          element: <RedirectToPresentationPage />,
          children: [],
        },
        {
          path: 'presentations/:presentationId/page/:pageNumber',
          element: (
            <PresentationProvider>
              <MoodboardLayout />
            </PresentationProvider>
          ),
          children: [],
        },
        {
          path: 'virtual_staging',
          element: <VirtualStaging />,
        },
      ],
    },
    {
      path: '*',
      element: <Page404 />,
    },
  ]);
}
