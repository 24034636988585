import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store/store';
import LoadingScreen from '../components/Templates/Loading/LoadingScreen';
import { UserRole } from 'types/user.type';
import { checkActive } from 'utils/user.utils';

type AuthGuardProps = {
  children: ReactNode;
  roles?: UserRole[];
};

const AuthGuard: React.FC<AuthGuardProps> = ({
  children,
  roles,
}: AuthGuardProps) => {
  const [isActive, setIsActive] = React.useState<boolean>(false);
  const [isLoadingActive, setIsLoadingActive] = React.useState<boolean>(true);
  const { user, isLoading } = useSelector(
    (state: RootState) => state.userReducer
  );

  React.useEffect(() => {
    if (user) {
      checkActive(user).then((isActive) => {
        setIsActive(isActive);
        setIsLoadingActive(false);
      });
    } else {
      setIsLoadingActive(false);
    }
  }, [user]);

  if (isLoading || isLoadingActive) {
    return <LoadingScreen />;
  }

  if (!user) {
    return <Navigate to={'/'} />;
  }

  if (!isActive) {
    return <Navigate to={'/register'} />;
  }

  if (roles) {
    if (!roles.includes(user.role)) {
      return <Navigate to={'/'} />;
    }
  }

  return <>{children}</>;
};

export default AuthGuard;
