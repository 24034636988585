import { SxProps, Typography } from '@mui/material';
import React from 'react';

interface TitleProps {
  title: string;
  sx?: SxProps;
}

const Title: React.FC<TitleProps> = ({ title, sx }) => {
  return (
    <Typography
      variant="h2"
      sx={{
        fontFamily: 'Bodoni Moda',
        fontWeight: 500,
        fontSize: '2rem',
        lineHeight: 1.2,
        textTransform: 'uppercase',
        ...sx,
      }}
    >
      {title}
    </Typography>
  );
};

export default Title;

