import React from 'react';
import { Box, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { NavItemDashboard } from 'types/navigation.type';
import { useNavigate } from 'react-router';
import { isMoodboardRoute } from '../../Moodboard/utils';

interface DrawerItemProps {
  item: NavItemDashboard;
  location: string;
  open: boolean;
}

const DrawerItem: React.FC<DrawerItemProps> = ({ item, location, open }) => {
  const navigate = useNavigate();
  const isActive = React.useMemo(
    () =>
      (item.path === '/dashboard'
        ? location === item.path
        : location.includes(item.path)) ||
      (isMoodboardRoute(item.path) && location.includes(item.path)),
    [location, item.path]
  );

  return (
    <ListItem
      key={item.title + item.path}
      disablePadding
      sx={{
        backgroundColor: isActive ? '#7d381b' : 'transparent',
        '&:hover': {
          backgroundColor: 'primay.dark',
        },
      }}
      onClick={() => {
        navigate(item.path);
      }}
    >
      <ListItemButton
        sx={{
          padding: '.5rem 1rem',
        }}
      >
        <Box
          sx={{
            minWidth: 38,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={item.icon}
            alt={item.title}
            style={{ maxWidth: 37, maxHeight: 34 }}
          />
        </Box>
        <ListItemText
          primary={item.title}
          sx={{
            textTransform: 'uppercase',
            fontWeight: 500,
            fontSize: '.9rem',
            opacity: open ? 1 : 0,
            transition: 'opacity .3s ease-in-out',
            marginLeft: '1rem',
            minWidth: 'max-content',
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default DrawerItem;
