import API from './api';

export default class PaymentApi {
  static async getPlans() {
    const response = await API.get('/payments/products');
    return response.data;
  }

  static async createCheckoutSession(planId: string) {
    const response = await API.post(
      `/payments/create-checkout-session?price_id=${planId}`,
      {}
    );
    return response.data;
  }

  static async createPortalSession(sessionId: string) {
    const response = await API.post(`/payments/create-portal-session`, {
      customer_id: sessionId,
    });
    return response.data;
  }
}

