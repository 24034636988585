import React from 'react';
import { useParams, Navigate } from 'react-router-dom';

const RedirectToPresentationPage: React.FC = () => {
  const { presentationId } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const role = urlParams.get('role');

  const url = role
    ? `/dashboard/presentations/${presentationId}/page/0?role=${role}`
    : `/dashboard/presentations/${presentationId}/page/0`;

  return <Navigate to={url} />;
};

export default RedirectToPresentationPage;
