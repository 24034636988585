import { User } from '../types/user.type';

export const checkActive = async (user: User) => {
  const isActive =
    user.is_admin || user.role === 'client' || user.is_trial_active
      ? true
      : user.subscription?.status === 'active';
  return isActive;
};

