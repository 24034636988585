import { UserCreationApi } from 'types/user.type';
import API from './api';

export default class UserApi {
  static async getUser() {
    const response = await API.get('/users/me');
    return response.data;
  }

  static async createUser(user: UserCreationApi) {
    const response = await API.post('/users/me', user);
    return response.data;
  }

  static async getContacts() {
    const response = await API.get('/contacts');
    return response.data;
  }
}

