import React, { Suspense } from 'react';
import LoadingScreen from '../Loading/LoadingScreen';

const Loadable = (Component: any) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default Loadable;

