import React from 'react';
import { Snackbar, Alert as MuiAlert } from '@mui/material';
import { useAlert } from 'hooks/useAlert'; // Import your AlertContext here

const Alert: React.FC = () => {
  const { alert, hideAlert } = useAlert();

  return (
    <Snackbar
      open={alert.isOpen}
      autoHideDuration={alert.duration}
      onClose={hideAlert}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      <MuiAlert severity={alert.severity} onClose={hideAlert} variant="filled">
        {alert.message}
      </MuiAlert>
    </Snackbar>
  );
};

export default Alert;

