const firebaseConfig = {
  apiKey: 'AIzaSyAZV9tawAmgf6ZDSSzNKgpqV8UPud8HTMg',
  authDomain: 'molyxa.firebaseapp.com',
  projectId: 'molyxa',
  storageBucket: 'molyxa.appspot.com',
  messagingSenderId: '558150567370',
  appId: '1:558150567370:web:9cd1044a5afcc33725fb33',
};

export default firebaseConfig;

