import React from 'react';
import { Typography, Modal, Box } from '@mui/material';

const TutoModal: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const params = new URLSearchParams(window.location.search);
  const tutorial = params.get('tutorial');

  const handleClose = () => {
    setOpen(false);
    window.history.replaceState({}, '', window.location.pathname);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  // open if there is a parameter tutorial in the url
  React.useEffect(() => {
    if (tutorial === 'true') {
      handleOpen();
    }
  }, [tutorial]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="Tutoriel"
      aria-describedby="Tutoriel"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#fff',
          padding: '0',
          width: '100%',
          maxWidth: 'min(90%, 800px)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '12px',
          border: '1px dashed rgba(0, 0, 0, 0.48)',
          position: 'relative',
        }}
        onClick={(event) => event.stopPropagation()}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            cursor: 'pointer',
          }}
          onClick={handleClose}
        >
          x
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: '1.5rem',
            borderBottom: '1px dashed rgba(0, 0, 0, 0.48)',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: '1.05rem',
              textTransform: 'uppercase',
              fontWeight: 500,
            }}
          >
            {'Tutoriel'}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: '2rem',
            backgroundColor: 'white',
            borderRadius: '0px 0px 12px 12px',
            minHeight: '50vh',
            '& iframe': {
              aspectRatio: 1.775,
            },
          }}
        >
          <iframe
            width="100%"
            src="https://www.youtube.com/embed/OUGH-sgskDk?autoplay=1"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            frameBorder="0"
          ></iframe>
        </Box>
      </Box>
    </Modal>
  );
};

export default TutoModal;

