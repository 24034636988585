import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store/store';
import LoadingScreen from '../components/Templates/Loading/LoadingScreen';

type GuestGuardProps = {
  children: ReactNode;
};

const GuestGuard: React.FC<GuestGuardProps> = ({
  children,
}: GuestGuardProps) => {
  const { user, isLoading } = useSelector(
    (state: RootState) => state.userReducer
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (user) {
    return <Navigate to={'/dashboard'} />;
  }

  return <>{children}</>;
};

export default GuestGuard;

