// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import firebaseConfig from '../keys/firebase';
import { updateProfile } from 'firebase/auth';

export default class Fire {
  // Initialize Firebase
  static init() {
    if (firebase.apps.length) return;

    firebase.initializeApp(firebaseConfig);
  }

  // Retrieve base auth
  static auth() {
    return firebase.auth();
  }

  static updateProfile(data: any) {
    const user = Fire.auth().currentUser;
    if (user) {
      return updateProfile(user, data);
    }
  }

  // Retrieve base firestore
  static store() {
    return firebase.firestore();
  }

  // Reauth
  static async reauthEmail(email: any, password: any) {
    const user = Fire.auth().currentUser;
    if (!user) return null;
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    );
    return user.reauthenticateWithCredential(credential);
  }

  // Shortcuts

  static async doc(ref: any) {
    const doc: any = await ref.get();
    if (!doc.exists) return null;
    return {
      id: doc.id,
      ...doc.data(),
    };
  }

  static get = async (collection: any, id: any) => {
    return Fire.doc(Fire.store().collection(collection).doc(id));
  };
}

