export const setToken = async (token: string) => {
  localStorage.setItem('token', token);
  localStorage.setItem(
    'lastLoginTime',
    new Date(Date.now()).getTime().toString()
  );
};

export const getToken = () => {
  const now = new Date(Date.now()).getTime();
  const timeAllowed = 1000 * 60 * 30;
  const lastLoginTime: any = localStorage.getItem('lastLoginTime');
  if (!lastLoginTime) {
    return null;
  }
  const timeSinceLastLogin = now - lastLoginTime;
  if (timeSinceLastLogin < timeAllowed) {
    return localStorage.getItem('token');
  }
};

export const deleteToken = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('lastLoginTime');
};

