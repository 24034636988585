import React from 'react';
import { Fade, Grid } from '@mui/material';
import Logo from 'assets/Logo.png';

interface LoadingScreenProps {
  width?: string;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ width }) => {
  return (
    <Fade in timeout={500}>
      <Grid
        container
        sx={{
          height: width || '100vh',
          width: width || '100vw',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={Logo}
          alt="Logo"
          style={{
            maxWidth: 300,
          }}
        />
      </Grid>
    </Fade>
  );
};

export default LoadingScreen;

