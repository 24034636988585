import { Box, Button, Divider, Drawer, List, Toolbar } from '@mui/material';
import React from 'react';
import { NavItemDashboard } from 'types/navigation.type';
import DrawerItem from './DrawerItem/DrawerItem';

import NavStudio from 'assets/NavStudio.svg';
import NavProjets from 'assets/NavProjets.svg';
import NavCatalogue from 'assets/NavCatalogue.svg';
import NavContacts from 'assets/NavContacts.svg';
import NavTuto from 'assets/NavTuto.svg';
import NavCatalogueG from 'assets/NavCatalogueG.svg';
import NavPresentation from 'assets/NavPresentation.png';
import NavInspirations from 'assets/NavInspirations.png';
import NavMoodboard from 'assets/NavMoodboard.png';
import NavVirtual from 'assets/NavVirtual.svg';

import { DrawerContext } from '../../../contexts/DrawerContext';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useUser from 'hooks/useUser';

const NAV_ITEMS: NavItemDashboard[] = [
  {
    title: 'Mon studio',
    icon: NavStudio,
    path: '/dashboard',
    isClientRoute: true,
  },
  {
    title: 'Mes Projets',
    icon: NavProjets,
    path: '/dashboard/projects',
    isClientRoute: true,
  },
  {
    title: 'Mon catalogue',
    icon: NavCatalogue,
    path: '/dashboard/catalogue',
  },
  {
    title: 'Créations',
    icon: NavMoodboard,
    path: '/dashboard/creations',
  },
  {
    title: 'Présentations',
    icon: NavPresentation,
    path: '/dashboard/presentations',
  },
  {
    title: 'Mes inspirations',
    icon: NavInspirations,
    path: '/dashboard/inspirations',
  },
  {
    title: 'Mes contacts',
    icon: NavContacts,
    path: '/dashboard/contacts',
  },
  {
    title: 'Tutoriel',
    icon: NavTuto,
    path: '?tutorial=true',
  },
  {
    title: 'Virtual Staging',
    icon: NavVirtual,
    path: '/dashboard/virtual_staging',
  },
];

const NAV_ITEMS_2: NavItemDashboard[] = [
  {
    title: 'CATALOGUE G',
    icon: NavCatalogueG,
    path: '/dashboard/general-catalogue',
  },
];

const LeftDrawer = () => {
  const location = window.location.pathname;
  const { open, width, toggleDrawer } = React.useContext(DrawerContext);
  const { isClient } = useUser();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: width,
        flexShrink: 0,
        transition: 'width .3s ease-in-out',
        [`& .MuiDrawer-paper`]: {
          width: width,
          boxSizing: 'border-box',
          transition: 'width .3s ease-in-out',
        },
      }}
    >
      <Toolbar
        sx={{
          minHeight: '75px !important',
        }}
      />
      <Box
        sx={{
          overflow: 'hidden',
          backgroundColor: 'primary.main',
          color: 'white',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <List
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '.5rem',
          }}
        >
          {NAV_ITEMS.map((item) =>
            !item.isClientRoute && isClient ? null : (
              <DrawerItem
                key={item.title + item.path}
                item={item}
                location={location}
                open={open}
              />
            )
          )}
        </List>
        <Divider
          sx={{
            borderColor: 'rgba(255, 255, 255, 0.50)',
            borderStyle: 'dashed',
          }}
        />
        <List
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            gap: '.5rem',
            paddingTop: '5rem',
          }}
        >
          {NAV_ITEMS_2.map((item, index) =>
            !item.isClientRoute && isClient ? null : (
              <DrawerItem
                key={item.title + item.path}
                item={item}
                location={location}
                open={open}
              />
            )
          )}
        </List>
        <Button
          sx={{
            color: 'white',
            width: '100%',
            padding: '1rem',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
          onClick={toggleDrawer}
        >
          <ArrowForwardIosIcon
            sx={{
              color: 'white',
              transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform .3s ease-in-out',
              width: '2rem',
            }}
          />
        </Button>
      </Box>
    </Drawer>
  );
};

export default LeftDrawer;
