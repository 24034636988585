import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddIcon from '@mui/icons-material/Add';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

const AnimateSx = {
  minWidth: 'max-content',
  width: 'fit-content',
  textDecoration: 'none',
  fontSize: '1rem',
  position: 'relative',
  padding: '0',
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
  '&::after': {
    position: 'absolute',
    content: '""',
    display: 'block',
    width: '0%',
    height: '1px',
    bottom: 0,
    left: 0,
    backgroundColor: 'primary.main',
    transition: 'width .3s ease-in-out',
  },
  '&:hover': {
    '&::after': {
      content: '""',
      display: 'block',
      width: '100%',
    },
  },
} as const;

const AnimateSxBackButton = {
  minWidth: 'max-content',
  width: 'fit-content',
  textDecoration: 'none',
  fontSize: '1rem',
  position: 'relative',
  padding: '0',
  '& .MuiTouchRipple-root': {
    display: 'none',
  },
  '& span': {
    transition: 'all .3s ease-in-out',
  },
  '&:hover span': {
    marginLeft: '-1rem',
    marginRight: '1rem',
  },
};

interface CustomButtonProps {
  fullWidth?: boolean;
  sx?: any;
  title?: string;
  variant?: 'text' | 'outlined' | 'contained';
  white?: boolean;
  onClick?: (e?: any) => void;
  path?: string;
  href?: string;
  smallPadding?: boolean;
  animateHover?: boolean;
  textTransform?:
    | 'none'
    | 'inherit'
    | 'capitalize'
    | 'uppercase'
    | 'lowercase'
    | 'full-width'
    | 'full-size-kana';
  disabled?: boolean;
  backButton?: boolean;
  arrow?: boolean;
  plus?: boolean;
  outLink?: boolean;
  videoBtn?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  fullWidth,
  sx,
  title,
  variant = 'contained',
  white,
  onClick,
  path,
  href,
  smallPadding,
  textTransform = 'capitalize',
  animateHover,
  disabled,
  backButton,
  arrow,
  plus,
  videoBtn,
  outLink,
}) => {
  const navigate = useNavigate();

  const onClickHandler = (e: any) => {
    if (onClick) {
      onClick(e);
    } else if (backButton && !path) {
      navigate(-1);
    } else {
      navigate(path || '');
    }
  };
  return (
    <Button
      variant={variant}
      href={href || ''}
      onClick={(e) => onClickHandler(e)}
      startIcon={backButton ? <ArrowBackIcon /> : null}
      target={outLink ? '_blank' : '_self'}
      endIcon={
        arrow ? (
          <ArrowForwardIcon />
        ) : plus ? (
          <AddIcon />
        ) : videoBtn ? (
          <PlayCircleIcon />
        ) : outLink ? (
          <OpenInNewIcon
            sx={{
              width: '1rem',
            }}
          />
        ) : null
      }
      sx={{
        '&.MuiButton-root': {
          borderRadius: '15px',
          padding: smallPadding ? '.5rem 1.2rem' : '.65rem 2rem',
          textTransform: textTransform,
          fontWeight: 500,
          backgroundColor: 'initial',

          fontSize: { xs: '1.15rem', sm: '1rem' },
          '&:hover': {
            backgroundColor: 'primary.main',
            color: white,
          },
        },
        '&.MuiButton-contained': {
          backgroundColor: white ? 'white' : 'primary.main',
          color: white ? 'primary.main' : 'white',
          borderWidth: white ? '1px' : 'none',
          borderColor: white ? 'primary.main' : 'none',
          borderStyle: white ? 'solid' : 'none',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: white ? 'primary.main' : 'primary.dark',
            color: 'white',
          },
        },
        '&.MuiButton-text': {
          color: 'white',
          ...(animateHover && backButton
            ? AnimateSxBackButton
            : animateHover && variant === 'text'
            ? AnimateSx
            : {}),
        },
        ...sx,
      }}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      {title}
    </Button>
  );
};

export default CustomButton;

