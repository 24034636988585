import { createSlice } from '@reduxjs/toolkit';

export interface paymentState {
  selectedPlan: string | null;
}

const initialState: paymentState = {
  selectedPlan: null,
};

const paymentSlice = createSlice({
  name: 'paymentReducer',
  initialState,
  reducers: {
    selectPlan: (state, action) => {
      state.selectedPlan = action.payload;
    },
  },
});

export const { selectPlan } = paymentSlice.actions;

export default paymentSlice.reducer;

