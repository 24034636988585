import { Grid, Fade, Typography } from '@mui/material';
import Footer from 'components/Footer/Footer';
import Navbar from 'components/Navbar/Navbar';
import ScrollToHashElement from 'components/Templates/Misc/ScrollToHashElement';
import Title from 'components/Templates/Titles/Title';

const Legal = () => {
  return (
    <Grid container>
      <Navbar />
      <ScrollToHashElement />
      <Fade in={true} timeout={500}>
        <Grid container>
          <Grid
            item
            flexDirection="column"
            sx={{
              p: 5,
              display: 'flex',
              gap: '1rem',
            }}
          >
            <Title title="Conditions Générales de Vente (CGV)" />
            <Typography
              component="span"
              sx={{
                fontFamily: 'Bodoni Moda',
                fontSize: '1.5rem',
                fontWeight: 700,
              }}
            >
              Article 1 : Préambule
            </Typography>
            <Typography>
              La société MOLYXA, immatriculée sous le numéro SIRET
              95071831200016, dont le siège social est situé 6 Avenue du Grand
              Large, 34300 AGDE, propose des services et outils d'intelligence
              artificielle dédiés aux professionnels de l'architecture et de la
              décoration d'intérieur. <br />
              <br />
              Les présentes Conditions Générales de Vente, désignées ci-après «
              CGV », régissent les relations contractuelles entre MOLYXA,
              ci-après nommée « l'Entreprise », et ses clients, ci-après
              désignés « Clients ».
            </Typography>
            <Typography
              component="span"
              sx={{
                fontFamily: 'Bodoni Moda',
                fontSize: '1.5rem',
                fontWeight: 700,
              }}
            >
              Article 2 :
            </Typography>{' '}
            <Typography>
              Définitions
              <br />
              <br />
              <ul>
                <li>
                  <strong> Accord :</strong> Tout devis, proposition
                  commerciale, ou contrat d'abonnement conclu entre l’Entreprise
                  et le Client décrivant les services souscrits.
                </li>
                <br />
                <br />
                <li>
                  <strong> Client :</strong> Tout professionnel ou entité
                  juridique qui établit un Accord avec l’Entreprise.
                  <br />
                  <br />
                </li>
                <li>
                  <strong> Livrables :</strong> Les résultats générés par les
                  services ou outils proposés, y compris les rapports, les
                  plans, et les visualisations.
                </li>
                <br />
                <br />
                <li>
                  <strong> Abonnement :</strong> Engagement périodique du Client
                  à utiliser les services de la plateforme en contrepartie d'un
                  paiement régulier.
                </li>
              </ul>
            </Typography>
            <Typography
              component="span"
              sx={{
                fontFamily: 'Bodoni Moda',
                fontSize: '1.5rem',
                fontWeight: 700,
              }}
            >
              Article 3 :
            </Typography>{' '}
            <Typography>
              Champ d’Application Ces CGV sont accessibles à tout Client sur
              demande et sont systématiquement communiquées pour toute
              souscription de services. La signature d’un Accord d'abonnement
              implique une adhésion sans réserve aux CGV. L’Entreprise se
              réserve le droit de modifier ces CGV à tout moment, les
              modifications prenant effet immédiatement pour les nouveaux
              abonnements et après notification pour les abonnements en cours.
            </Typography>
            <Typography
              component="span"
              sx={{
                fontFamily: 'Bodoni Moda',
                fontSize: '1.5rem',
                fontWeight: 700,
              }}
            >
              Article 4 :
            </Typography>{' '}
            <Typography>
              Souscription et Renouvellement d'Abonnement Les abonnements sont
              souscrits pour une période minimale définie dans l’Accord. Le
              renouvellement de l’abonnement est automatique sauf résiliation
              par l’une des parties, notifiée par écrit au moins 30 jours avant
              la fin de la période en cours.
            </Typography>
            <Typography
              component="span"
              sx={{
                fontFamily: 'Bodoni Moda',
                fontSize: '1.5rem',
                fontWeight: 700,
              }}
            >
              Article 5 :
            </Typography>{' '}
            <Typography>
              Engagement et Obligations du Client Le Client s'engage à fournir
              toutes les informations nécessaires pour la bonne réalisation des
              services et à régler les frais d'abonnement aux échéances
              convenues.
            </Typography>
            <Typography
              component="span"
              sx={{
                fontFamily: 'Bodoni Moda',
                fontSize: '1.5rem',
                fontWeight: 700,
              }}
            >
              Article 6 :
            </Typography>{' '}
            <Typography>
              Force Majeure L’exécution des obligations de l’Entreprise sera
              suspendue en cas de survenance d’un événement de force majeure qui
              en empêcherait ou en retarderait l’exécution.
            </Typography>
            <Typography
              component="span"
              sx={{
                fontFamily: 'Bodoni Moda',
                fontSize: '1.5rem',
                fontWeight: 700,
              }}
            >
              Article 7 :
            </Typography>{' '}
            <Typography>
              Prestations Les services accessibles via l'abonnement à la
              plateforme peuvent inclure, sans s’y limiter, la fourniture
              d'outils d'analyse, de conception assistée par ordinateur, de
              visualisation 3D, et d'optimisation par intelligence artificielle.
            </Typography>
            <Typography
              component="span"
              sx={{
                fontFamily: 'Bodoni Moda',
                fontSize: '1.5rem',
                fontWeight: 700,
              }}
            >
              Article 8 :
            </Typography>{' '}
            <Typography>
              Paiement Les paiements sont exigibles selon les termes de l’Accord
              et doivent être réalisés aux échéances convenues. Tout retard de
              paiement entraînera des pénalités de retard calculées à un taux
              précisé dans l’Accord.
            </Typography>
            <Typography
              component="span"
              sx={{
                fontFamily: 'Bodoni Moda',
                fontSize: '1.5rem',
                fontWeight: 700,
              }}
            >
              Article 9 :
            </Typography>{' '}
            <Typography>
              Propriété Intellectuelle L’Entreprise reste propriétaire des
              droits de propriété intellectuelle sur tous les Livrables fournis.
              Le Client obtient une licence d’utilisation limitée, non exclusive
              et non transférable pour les Livrables à des fins internes.
            </Typography>
            <Typography
              component="span"
              sx={{
                fontFamily: 'Bodoni Moda',
                fontSize: '1.5rem',
                fontWeight: 700,
              }}
            >
              Article 10 :
            </Typography>{' '}
            <Typography>
              Confidentialité Les deux parties s'engagent à garder
              confidentielles les informations échangées dans le cadre de leur
              collaboration.
            </Typography>
            <Typography
              component="span"
              sx={{
                fontFamily: 'Bodoni Moda',
                fontSize: '1.5rem',
                fontWeight: 700,
              }}
            >
              Article 11 :
            </Typography>{' '}
            <Typography>
              Résiliation d’Abonnement L’Abonnement peut être résilié par l’une
              ou l’autre des parties en respectant un préavis écrit de 30 jours.
              En cas de résiliation, le Client reste tenu de régler toutes les
              sommes dues jusqu'à la date de résiliation effective.
            </Typography>
            <Typography
              component="span"
              sx={{
                fontFamily: 'Bodoni Moda',
                fontSize: '1.5rem',
                fontWeight: 700,
              }}
            >
              Article 12 :
            </Typography>{' '}
            <Typography>
              Litiges Tout litige relatif à l’interprétation ou à l’exécution
              des présentes CGV sera soumis aux tribunaux compétents dans le
              ressort du siège social de l’Entreprise, sauf disposition légale
              contraire.
            </Typography>
            <Typography
              component="span"
              sx={{
                fontFamily: 'Bodoni Moda',
                fontSize: '1.5rem',
                fontWeight: 700,
              }}
            >
              Article 13 :
            </Typography>{' '}
            <Typography>
              Dispositions Générales Si une partie des CGV est jugée invalide ou
              inapplicable, les autres dispositions restent en vigueur. Les CGV
              expriment l'intégralité des obligations des parties.
            </Typography>
          </Grid>
          <Footer />
        </Grid>
      </Fade>
    </Grid>
  );
};

export default Legal;

