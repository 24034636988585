import React, { createContext } from 'react';
import CustomAlert from '../components/Templates/Alert/CustomAlert';

interface AlertContextProps {
  message: string | null;
  severity: severity;
  duration: number;
  isOpen: boolean;
}

type severity = 'success' | 'info' | 'warning' | 'error';

export const AlertContext = createContext({
  alert: {
    message: null,
    severity: 'info' as severity,
    duration: 5000,
    isOpen: false,
  } as AlertContextProps,
  hideAlert: () => {},
  showAlert: (severity: severity, message: string, timeout?: number) => {},
});

const AlertContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [alert, setAlert] = React.useState<AlertContextProps>({
    severity: 'info',
    message: null,
    duration: 5000,
    isOpen: false,
  });

  const showAlert = React.useCallback(
    (severity: severity = 'info', message: string, timeout?: number) => {
      setAlert({ message, severity, isOpen: true, duration: timeout || 5000 });
    },
    []
  );

  const hideAlert = React.useCallback(() => {
    setAlert((prev) => ({
      ...prev,
      isOpen: false,
    }));
  }, []);

  return (
    <AlertContext.Provider value={{ alert, showAlert, hideAlert }}>
      {children}
      <CustomAlert />
    </AlertContext.Provider>
  );
};

export default AlertContextProvider;

