import React, { ReactNode, createContext, useState } from 'react';

interface DrawerContextProps {
  open: boolean;
  width: number;
  toggleDrawer: () => void;
}

const initialState: DrawerContextProps = {
  open: true,
  width: 240,
  toggleDrawer: () => {},
};

const DrawerContext = createContext(initialState);

type DrawerProviderProps = {
  children: ReactNode;
};

function DrawerProvider({ children }: DrawerProviderProps) {
  const [isOpen, setIsOpen] = useState(true);
  const [width, setWidth] = useState(240);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  React.useEffect(() => {
    if (isOpen) {
      setWidth(240);
    } else {
      setWidth(70);
    }
  }, [isOpen]);

  return (
    <DrawerContext.Provider
      value={{
        open: isOpen,
        width,
        toggleDrawer,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
}

export { DrawerProvider, DrawerContext };
