import { createSlice } from '@reduxjs/toolkit';
import { User } from 'types/user.type';
import Fire from 'services/fire.service';
import { deleteToken } from 'services/localstorage.service';

export interface UserState {
  user: User | null;
  isLoading: boolean;
  isUserChecked?: boolean;
}

const initialState: UserState = {
  user: null,
  isLoading: true,
};

const userSlice = createSlice({
  name: 'userReducer',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    login: (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
    },
    logout: (state) => {
      state.user = null;
      state.isLoading = false;

      deleteToken();
      Fire.auth().signOut();
    },
    updateUser: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
  },
});

export const { login, logout, updateUser, setLoading } = userSlice.actions;

export default userSlice.reducer;

