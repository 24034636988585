import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Logo_white from 'assets/Logo_white.png';
import { Link } from 'react-router-dom';
import Facebook_icon from 'assets/Facebook_icon.svg';
import Instagram_icon from 'assets/Instagram_icon.svg';
import Pinterest_icon from 'assets/Pinterest_icon.svg';
import Linkedin_icon from 'assets/Linkedin_icon.svg';

const TypographySx = {
  minWidth: 'max-content',
  width: 'fit-content',
  color: 'white',
  textDecoration: 'none',
  fontSize: '1rem',
  position: 'relative',
  '&::after': {
    position: 'absolute',
    content: '""',
    display: 'block',
    width: '0%',
    height: '1px',
    backgroundColor: 'white',
    transition: 'width .3s ease-in-out',
  },
  '&:hover': {
    '&::after': {
      content: '""',
      display: 'block',
      width: '100%',
    },
  },
} as const;

const Footer: React.FC = () => {
  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        backgroundColor: 'primary.main',
        padding: { xs: '2rem', sm: '4rem' },
        color: 'white',
      }}
    >
      <Grid
        container
        item
        xs={12}
        sm={5}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          marginBottom: {
            xs: '4rem',
            sm: '0',
          },
        }}
      >
        <img
          src={Logo_white}
          alt="Logo_white"
          style={{ maxWidth: '185px', height: 'auto' }}
        />
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={7}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'space-between', sm: 'flex-end' },
            alignItems: 'flex-start',
            width: '100%',
            gap: { xs: '.5rem', sm: '2rem', md: '4rem', lg: '6rem' },
          }}
        >
          <ul
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <li
              style={{
                listStyle: 'none',
              }}
            >
              <Link
                to="https://blog.molyxa.com/notre-histoire/"
                style={{
                  textDecoration: 'none',
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    ...TypographySx,
                  }}
                >
                  Notre histoire
                </Typography>
              </Link>
            </li>
            <li
              style={{
                listStyle: 'none',
              }}
            >
              <Link
                to="https://blog.molyxa.com/a-propos/"
                style={{
                  textDecoration: 'none',
                }}
              >
                <Typography variant="body2" sx={TypographySx}>
                  À propos
                </Typography>
              </Link>
            </li>
            <li
              style={{
                listStyle: 'none',
              }}
            >
              <Link
                to="/legal"
                style={{
                  textDecoration: 'none',
                }}
              >
                <Typography variant="body2" sx={TypographySx}>
                  Mentions légales
                </Typography>
              </Link>
            </li>
          </ul>
          <ul
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <li
              style={{
                listStyle: 'none',
              }}
            >
              <Link
                to="https://blog.molyxa.com/contact/"
                style={{
                  textDecoration: 'none',
                }}
                target="_blank"
              >
                <Typography variant="body2" sx={TypographySx}>
                  Nous contacter
                </Typography>
              </Link>
            </li>
            <li
              style={{
                listStyle: 'none',
              }}
            >
              <Link
                to="https://blog.molyxa.com/faq/"
                style={{
                  textDecoration: 'none',
                }}
              >
                <Typography variant="body2" sx={TypographySx}>
                  FAQ
                </Typography>
              </Link>
            </li>
            <li
              style={{
                listStyle: 'none',
              }}
            >
              <Link
                to="/cgv"
                style={{
                  textDecoration: 'none',
                }}
              >
                <Typography variant="body2" sx={TypographySx}>
                  CGV
                </Typography>
              </Link>
            </li>
          </ul>

          <ul>
            <li
              style={{
                listStyle: 'none',
                marginBottom: '1rem',
              }}
            >
              <Link
                to="https://www.facebook.com/profile.php?id=100063716629098"
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                }}
                target="_blank"
              >
                <img
                  src={Facebook_icon}
                  alt="Facebook_icon"
                  style={{
                    marginRight: '.5rem',
                    width: '1.25rem',
                    height: '1.25rem',
                  }}
                />

                <Typography variant="body2" sx={TypographySx}>
                  Facebook
                </Typography>
              </Link>
            </li>
            <li
              style={{
                listStyle: 'none',
                marginBottom: '1rem',
              }}
            >
              <Link
                to="https://www.instagram.com/mydecoai/"
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                }}
                target="_blank"
              >
                <img
                  src={Instagram_icon}
                  alt="Instagram_icon"
                  style={{
                    marginRight: '.5rem',
                    width: '1.25rem',
                    height: '1.25rem',
                  }}
                />

                <Typography variant="body2" sx={TypographySx}>
                  Instagram
                </Typography>
              </Link>
            </li>
            <li
              style={{
                listStyle: 'none',
                marginBottom: '1rem',
              }}
            >
              <Link
                to="https://www.pinterest.fr/molyxa/"
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                }}
                target="_blank"
              >
                <img
                  src={Pinterest_icon}
                  alt="Pinterest_icon"
                  style={{
                    marginRight: '.5rem',
                    width: '1.25rem',
                    height: '1.25rem',
                  }}
                />

                <Typography variant="body2" sx={TypographySx}>
                  Pinterest
                </Typography>
              </Link>
            </li>
            <li
              style={{
                listStyle: 'none',
                marginBottom: '1rem',
              }}
            >
              <Link
                to="https://www.linkedin.com/company/89654700/admin/feed/posts/"
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                }}
                target="_blank"
              >
                <img
                  src={Linkedin_icon}
                  alt="Facebook_icon"
                  style={{
                    marginRight: '.5rem',
                    width: '1.25rem',
                    height: '1.25rem',
                  }}
                />

                <Typography variant="body2" sx={TypographySx}>
                  Linkedin
                </Typography>
              </Link>
            </li>
          </ul>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Footer;

