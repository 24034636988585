import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { useLocation } from 'react-router-dom';

interface PresentationContextType {
  isPresentation: boolean;
  setIsPresentation: (isPresentation: boolean) => void;
}

const PresentationContext = createContext<PresentationContextType>({
  isPresentation: false,
  setIsPresentation: () => {},
});

export const useIsPresentation = () => useContext(PresentationContext);

export const PresentationProvider = ({ children }: { children: ReactNode }) => {
  const [isPresentation, setIsPresentation] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const isPresentationRoute = location.pathname.includes(
      '/dashboard/presentations'
    );
    setIsPresentation(isPresentationRoute);
  }, [location]);

  return (
    <PresentationContext.Provider value={{ isPresentation, setIsPresentation }}>
      {children}
    </PresentationContext.Provider>
  );
};
