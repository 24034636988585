import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../slices/user/userSlice';
import paymentReducer from '../slices/payment/paymentSlice';

export type RootState = ReturnType<typeof store.getState>;

export const store = configureStore({
  reducer: {
    userReducer: userReducer,
    paymentReducer: paymentReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

